import "../styles/about.css"
import Header from '../components/Header';
import ContactForm from '../components/ContactForm';
import { useEffect } from "react"


function Contact() {
    return (<>
        <Header />
        <ContactForm />
    </>
    )
}


export default Contact