import "../styles/artists.css"
import Header from '../components/Header';
import { useEffect } from "react"


function Artists() {
    return (<>
        <Header />
        <div className="artists-main">
            <div className="text-right artists-page-title"><h2>THE HAPPENSTANCERS</h2></div>
            <div className="text-ctr text-right rotate12 artists-1"><h2>BRAD CHERWIN. <span className="desktop-only">CLARINET. </span>ARTISTIC DIRECTOR<br />HOI TONG KEUNG. PRODUCTION MANAGER<br />SIMON RIVARD. CONDUCTOR<br />BILLY WONG. LIGHTING DESIGN</h2></div>
            <div className="artists-2 text-ctr text-left"><h2>HEE-SOO YOON. VIOLIN AND VIOLA<br />PETER EOM. CELLO</h2></div>
            <div className="artists-3 text-right text-ctr"><h2>ALEH REMEZAU. OBOE<br />LOUIS PINO. PERCUSSION<br />LENNY RANALLO. GUITAR</h2></div>
            <div className="artists-4 text-left text-ctr rotate-5"><h2 >DANIKA LOREN. VOICE<br />REILLY NELSON. VOICE</h2></div>
            <div className="artists-5 text-left text-ctr rotate7"><h2>KEVIN AHFAT. PIANO AND KEYBOARDS</h2></div>
        </div>
    </>
    )
}


export default Artists