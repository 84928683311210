import "../styles/upcoming.css"
import Header from '../components/Header';
import { useEffect } from "react"


function Upcoming() {
    return (<>
        <Header />
        <div className="upcoming-main">
            <div className="text-ctr upcoming-page-title"><h2 className="text-left">UPCOMING<br />PERFORMANCES</h2></div>
            <div className="upcoming-content">
                <div className="rotate7 text-ctr upcoming-top"><h2 className="text-right"><span className="diagonal">WITH YOU AND WITHOUT YOU</span><br />MARCH 22ND AT 730 PM<br />PRESENCE AND ABSENCE<br />EMBRACE<br />IN COLD LOVING OPPOSITION<br />SONGS FOR VOICE AND ENSEMBLE<br />PRESENTED WITH SOUNDSTREAMS<br />TICKETS ARE <a target="_blank" href="https://www.ticketmaster.ca/with-you-and-without-you-toronto-ontario-03-22-2025/event/10006143F3728CD8"><span className="diagonal">HERE</span></a></h2></div>
                <div className="center-div text-ctr"><h2 className="text-left">2025 PERFORMANCES AND FESTIVAL DATES<br />TO BE ANNOUNCED<br /><br />AND SOON<br /><br />WHAT IS<br />THE TWO DEATHS OF OPHELIA<br />SEVEN</h2></div>
            </div>
        </div>
    </>
    )
}


export default Upcoming