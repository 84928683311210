import "../styles/about.css"
import "../styles/media.css"
import Header from '../components/Header';
import { useEffect } from "react"


function Media() {

    useEffect(() => {
        const app = document.querySelector(".App");
        app.style.overflowY = "visible"
    }, [])
    return (<>
        <Header />

        <div className="media-main">
            <div className="frame1 iframe-ctr"><iframe src="https://www.youtube.com/embed/H0dYPqEUS4M" title="Schoenberg - Der Mondfleck (The Happenstancers)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
            <div className="frame2 iframe-ctr"><iframe src="https://www.youtube.com/embed/SxxlKn9F2Q4" title="Schoenberg - Rote Messe (The Happenstancers)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
            <div className="frame3 iframe-ctr"><iframe src="https://www.youtube.com/embed/VOMvNgI1D7I" title="Schoenberg - Rote Messe (The Happenstancers)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe></div>
        </div>
    </>
    )
}


export default Media