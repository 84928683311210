import "../styles/about.css"
import Header from '../components/Header';
import { useEffect } from "react"


function Funders() {
    useEffect(() => {
        const app = document.querySelector(".App");
        app.style.overflowY = "hidden"
    }, [])
    return (<>
        <Header />
        Funders
    </>
    )
}


export default Funders