import "../styles/happlab.css"
import Header from '../components/Header';
import { useEffect } from "react"


function HappLab() {
    return (<>
        <Header />
        <div className="happlab-main">
            <div className="text-ctr happlab-page-title"><h2 >HAPPLAB</h2></div>
            <div className="happlab-content">
                <div className="rotate7 happlab-1"><h2 className="text-right">
                    HAPPLAB IS A MENTORSHIP<br />
                    CURRICULUM AND <br />
                    PERFORMANCE AND<br />
                    PRESENCE AND ABSENCE<br />
                    PRESENTATION LABORATORY<br />
                    FOR EARLY CAREER ARTISTS<br />
                    WORKING IN <br />
                    OR AROUND CONTEMPORARY <br />
                    CLASSICAL MUSIC. WE TAKE AN <br />
                    EXPANSIVE VIEW OF THIS  <br />
                    CLASSIFICATION.
                </h2></div>
                <div className="center-div happlab-2"><h2 className="text-left">
                    WITH THE HELP OF <br />
                    THE HAPPENSTANCERS <br />
                    EACH PARTICIPANT WILL <br />
                    COMPLETE TWO GRANT <br />
                    APPLICATIONS RELATED TO A<br />
                    PROJECT OF THEIR CHOOSING BY <br />
                    THE END OF THE YEAR LONG<br />
                    CURRICULUM.
                </h2></div>
                <div className="happlab-3"><h2>
                    THE HAPPENSTANCERS WILL<br />
                    PROVIDE A PROFESSIONAL<br />
                    RECORDING SESSION <br />

                    AND 1500.00 CAD <br />

                    IN SEED FUNDING FOR EACH<br />
                    ARTIST TO BE USED IN SUPPORT<br />
                    OF EACH ARTISTS PERFORMANCE <br />
                    PROJECT. TOGETHER, THESE <br />
                    ELEMENTS WILL CREATE A <br />
                    FOUNDATION FOR THE<br />
                    CONTINUED ARTISTIC WORK OF <br />
                    EACH COHORT MEMBER AS THEY <br />
                    EXIT THE HAPPLAB PROGRAMME.
                </h2></div>
                <div className="center-div  happlab-4"><h2 className="text-left">
                    FOR MORE INFORMATION AND TO <br />
                    APPLY ... <a target="_blank" href="https://drive.google.com/file/d/1-slUQIYOM9rYzr91cY_wqqbFDRV5jSTL/view?usp=drivesdk" ><span className="diagonal">CLICK HERE</span></a>
                </h2></div>
            </div>

        </div>
    </>
    )
}


export default HappLab