import "../styles/about.css"
import Header from '../components/Header';
import Landing from '../components/Landing';


function Contact() {
    return (<>
        <Header />
        <Landing />
    </>
    )
}


export default Contact