import "../styles/about.css"
import Header from '../components/Header';
import { useEffect } from "react"


function About() {

    return (<>
        <Header />
        <div className="about-main">
            <div className="text-ctr about-1"><h2 className="text-right">THE HAPPENSTANCERS<br />IS AN ENSEMBLE<br />OF MUSICIANS<br />IN TORONTO</h2></div>
            <div className="text-ctr rotate-15 about-2"><h2 className="text-left">THE HAPPENSTANCERS<br />IS A LABORATORY<br />FOR EXPERIMENTATION<br />WITH THE FORM<br />AND FUNCTION<br />OF CLASSICAL MUSIC<br />[WHATEVER THAT IS]</h2></div>
            <div className="center-div text-ctr about-3"><h2 className="text-left">THE HAPPENSTANCERS<br />IS A GROUP OF ARTISTS<br />WALKING<br />NEW PATHWAYS<br /></h2></div>
        </div>
    </>
    )
}


export default About